import React from "react"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import VerseMeasureEvaluation from "../../../../../components/versemeasure-evaluation"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const taskProps = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "versmass-bestimmen",
  }
  const answer = getAnswer(taskProps)
  const task = getTask(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ ids, children }) => {
    return (
      <VerseMeasureEvaluation
        ids={ids}
        answer={answer}
        solution={task.solution}
      >
        {children}
      </VerseMeasureEvaluation>
    )
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/versmass-bestimmen/zusammenfassung-1" />
      }
    >
      <Seo title="Versmaß bestimmen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Versmaß bestimmen
            </Heading>
          </Stack>
          <FeedbackHeaderProgress
            correct={statistics.correct}
            total={
              statistics.correct + statistics.incorrect + statistics.missed
            }
          />

          <RenderAnswer ids={["q-0", "q-1", "q-2", "q-3", "q-4"]}>
            Hälfte des Lebens
          </RenderAnswer>
          <RenderAnswer
            ids={["q-5", "q-6", "q-7", "q-8", "q-9", "q-10", "q-11"]}
          >
            Mit gelben Birnen hänget
          </RenderAnswer>
          <RenderAnswer
            ids={["q-12", "q-13", "q-14", "q-15", "q-16", "q-17", "q-18"]}
          >
            Und voll mit wilden Rosen
          </RenderAnswer>
          <RenderAnswer ids={["q-19", "q-20", "q-21", "q-22", "q-23"]}>
            Das Land in den See,
          </RenderAnswer>
          <RenderAnswer ids={["q-24", "q-25", "q-26", "q-27", "q-28"]}>
            Ihr holden Schwäne,
          </RenderAnswer>
          <RenderAnswer ids={["q-29", "q-30", "q-31", "q-32", "q-33", "q-34"]}>
            Und trunken von Küssen
          </RenderAnswer>
          <RenderAnswer ids={["q-35", "q-36", "q-37", "q-38"]}>
            Tunkt ihr das Haupt
          </RenderAnswer>
          <RenderAnswer
            ids={[
              "q-39",
              "q-40",
              "q-41",
              "q-42",
              "q-43",
              "q-44",
              "q-45",
              "q-46",
            ]}
          >
            Ins heilignüchterne Wasser.
          </RenderAnswer>
          <RenderAnswer ids={["q-47", "q-48", "q-49", "q-50", "q-51", "q-52"]}>
            Weh mir, wo nehm’ ich, wenn
          </RenderAnswer>
          <RenderAnswer
            ids={[
              "q-53",
              "q-54",
              "q-55",
              "q-56",
              "q-57",
              "q-58",
              "q-59",
              "q-60",
              "q-61",
            ]}
          >
            Es Winter ist, die Blumen, und wo
          </RenderAnswer>
          <RenderAnswer ids={["q-62", "q-63", "q-64", "q-65"]}>
            Den Sonnenschein,
          </RenderAnswer>
          <RenderAnswer ids={["q-66", "q-67", "q-68", "q-69", "q-70", "q-71"]}>
            Und Schatten der Erde?
          </RenderAnswer>
          <RenderAnswer ids={["q-72", "q-73", "q-74", "q-75"]}>
            Die Mauern stehn
          </RenderAnswer>
          <RenderAnswer
            ids={["q-76", "q-77", "q-78", "q-79", "q-80", "q-81", "q-82"]}
          >
            Sprachlos und kalt, im Winde
          </RenderAnswer>
          <RenderAnswer ids={["q-83", "q-84", "q-85", "q-86", "q-87"]}>
            Klirren die Fahnen.
          </RenderAnswer>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
