import React from "react"
import PropTypes from "prop-types"

import Icon from "./icon"
import Inline from "./inline"
import Text from "./text"
import Grid from "./grid"
import Box from "./box"
import Poem from "./poem"
import Stack from "./stack"

const VerseMeasureEvaluation = ({ children, size, ids, answer, solution }) => {
  const isValid = (id) => {
    if (typeof answer === "undefined" || answer === null || !answer[id]) {
      return false
    }

    return solution[id] === answer[id]
  }
  return (
    <Stack space={3}>
      <Poem size={size}>{children}</Poem>
      <Grid columns={[1, 2]} space={6}>
        <Box sx={{ bg: "muted", p: 6, borderRadius: "lg", height: "100%" }}>
          <Stack space={3}>
            <Text sans size={1} color="whisper">
              Deine Antwort
            </Text>
            <Inline>
              {ids.map((id) => (
                <Box key={`answer-${id}`}>
                  <Text sans mb={1} align="center" size={4}>
                    {answer?.[id] || "?"}
                  </Text>
                  <Icon
                    icon={isValid(id) ? "done" : "close"}
                    color={isValid(id) ? "positive" : "negative"}
                  />
                </Box>
              ))}
            </Inline>
          </Stack>
        </Box>
        <Box sx={{ bg: "muted", p: 6, borderRadius: "lg", height: "100%" }}>
          <Stack space={3}>
            <Text sans size={1} color="whisper">
              Lösung
            </Text>
            <Inline>
              {ids.map((id) => (
                <Text sans key={`solution-${id}`} size={4}>
                  {solution[id]}
                </Text>
              ))}
            </Inline>
          </Stack>
        </Box>
      </Grid>
    </Stack>
  )
}

VerseMeasureEvaluation.propTypes = {
  children: PropTypes.node,
  /** size of the poem */
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  ids: PropTypes.array.isRequired,
}

export default VerseMeasureEvaluation
